export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'posts',
      displayName: 'menu.posts',
      meta: {
        icon: 'post_add',
      },
    },
    {
      name: 'services',
      displayName: 'menu.services',
      meta: {
        icon: 'devices',
      },
    },
    {
      name: 'appointments',
      displayName: 'menu.appointments',
      meta: {
        icon: 'calendar_month',
      },
    },
    {
      name: 'serviceOrders',
      displayName: 'menu.serviceOrders',
      meta: {
        icon: 'list_alt',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'influencers',
      displayName: 'menu.influencers',
      meta: {
        icon: 'video_camera_front',
      },
    },
    {
      name: 'freelancers',
      displayName: 'menu.freelancers',
      meta: {
        icon: 'badge',
      },
    },
    {
      name: 'freelancerCategorys',
      displayName: 'menu.freelancerCategorys',
      meta: {
        icon: 'format_list_bulleted',
      },
    },
    {
      name: 'merchandises',
      displayName: 'menu.merchandises',
      meta: {
        icon: 'store',
      },
    },
    {
      name: 'tickets',
      displayName: 'menu.tickets',
      meta: {
        icon: 'contact_support',
      },
    },
  ] as INavigationRoute[],
}
