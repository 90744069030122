<template>
  <div class="app-navbar-actions">
    <VaButton
      preset="secondary"
      color="textPrimary"
      class="app-navbar-actions__item flex-shrink-0 mx-0"
      @click="logOut"
    >
      Logout
    </VaButton>
  </div>
</template>

<script lang="ts" setup>
import ProfileDropdown from './dropdowns/ProfileDropdown.vue'
import NotificationDropdown from './dropdowns/NotificationDropdown.vue'
import GithubButton from './GitHubButton.vue'
import { useRouter } from 'vue-router'
import { db, app } from '../../../api/firebase'
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth'

defineProps({
  isMobile: { type: Boolean, default: false },
})

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { push } = useRouter()

const logOut = () => {
  try {
    // Add your logout logic here
    const auth = getAuth(app)
    signOut(auth)
      .then(() => {
        console.log('You have signed out successfully')
        localStorage.setItem('influencer', '')
        push({ name: 'login' })
      })
      .catch((error) => {
        // An error happened.
      })
  } catch (error) {
    console.error('Error during logOut:', error)
  }
}
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
</style>
