import moment from 'moment'
import { StorageKeys, StorageModel } from './localStorageType'

export const expireHours = 8

export const setStorageData = (key: StorageKeys, data: any, refreshHours?: number): void => {
  if (typeof window !== 'undefined') {
    const getExpireTime = getExpireDate(refreshHours)
    // console.log("getExpireTime");
    // console.log(getExpireTime);
    const storageData: StorageModel = {
      data: data,
      expireDate: getExpireTime == null ? null : getExpireTime,
    }
    localStorage.setItem(key, JSON.stringify(storageData))
  }
}

export const getStorageData = (key: StorageKeys) => {
  if (typeof window !== 'undefined') {
    const storageString = localStorage.getItem(key)
    if (storageString) {
      const val: StorageModel = JSON.parse(storageString)
      if (val.expireDate == null) return val.data

      const expireDate = moment(val.expireDate).format('YYYY-MM-DD[T]HH:mm:ss')

      // console.log("expireDate");
      // console.log(isNotExpired(expireDate));
      if (isNotExpired(expireDate)) return val.data
      else localStorage.removeItem(key)
    }
  }

  return null
}

export const removeStorageData = (key: StorageKeys) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key)
  }
}

const getExpireDate = (refreshHours?: number) => {
  if (refreshHours) {
    const expireDate = moment().add(refreshHours, 'hours')
    // console.log("expireDate in moment");
    // console.log(expireDate.format("YYYY-MM-DD[T]HH:mm:ss"));
    // console.log(expireDate.format("YYYY-MM-DD[T]HH:mm:ss"));
    // console.log("conver a to moment after format");
    // console.log(a);

    // let expireDate = new Date(
    //   currentDate.getTime() + refreshHours * 60 * 60 * 1000
    // );
    // let oneMinuteInMillis = 1 * 60 * 1000;
    // let expireDate = new Date(currentDate.getTime() + oneMinuteInMillis);
    // console.log("ExpireDate: " + expireDate);
    return expireDate.format('YYYY-MM-DD[T]HH:mm:ss')
  } else {
    return null
  }
}

const isNotExpired = (date: string): boolean => {
  const currentDate = moment()
  const expiredDate = moment(date)
  // console.log("currentDate");
  // console.log(currentDate);
  // console.log("expiredDate");
  // console.log(expiredDate);
  // 2024-10-20 > 2024-10-19 true
  return expiredDate.isSameOrAfter(currentDate)
}
