import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// const firebaseConfig = {
//   apiKey: 'AIzaSyCtPgT4bBMUzs4Sv4TbNibCR-KHYB8gPEk',
//   authDomain: 'zeta-kol.firebaseapp.com',
//   projectId: 'zeta-kol',
//   storageBucket: 'zeta-kol.appspot.com',
//   messagingSenderId: '790007488204',
//   appId: '1:790007488204:web:3793b42c35114457aa2c69',
//   measurementId: 'G-NVLEVZTGDY',
// }

const firebaseConfig = {
  apiKey: 'AIzaSyD_qXguk0WQ-IhaABRDJsxuXVZRjIJciBY',
  authDomain: 'project-testing-v2.firebaseapp.com',
  projectId: 'project-testing-v2',
  storageBucket: 'project-testing-v2.appspot.com',
  messagingSenderId: '753745478159',
  appId: '1:753745478159:web:16b770f37849def75f197a',
  measurementId: 'G-9X5H7BYGV7',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export { db, app }
