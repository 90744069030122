import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import { getStorageData } from '../services/localstorage'
import { StorageKeys } from '../services/localstorage/localStorageType'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'influencers' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    meta: { requiresAuth: true },
    redirect: { name: 'influencers' },
    children: [
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'influencers',
        path: 'influencers',
        component: () => import('../pages/influencers/InfluencersPage.vue'),
      },
      {
        name: 'freelancers',
        path: 'freelancers',
        component: () => import('../pages/freelancers/FreelancersPage.vue'),
      },
      {
        name: 'freelancerCategorys',
        path: 'freelancerCategorys',
        component: () => import('../pages/freelancerCategorys/FreelancerCategorysPage.vue'),
      },
      {
        name: 'merchandises',
        path: 'merchandises',
        component: () => import('../pages/merchandises/MerchandisesPage.vue'),
      },
      {
        name: 'appointments',
        path: 'appointments',
        component: () => import('../pages/appointments/AppointmentsPage.vue'),
      },
      {
        name: 'serviceOrders',
        path: 'serviceOrders',
        component: () => import('../pages/serviceOrders/ServiceOrdersPage.vue'),
      },
      {
        name: 'posts',
        path: 'posts',
        component: () => import('../pages/posts/PostsPage.vue'),
      },
      {
        name: 'services',
        path: 'services',
        component: () => import('../pages/services/ServicesPage.vue'),
      },
      {
        name: 'tickets',
        path: 'tickets',
        component: () => import('../pages/tickets/TicketsPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem('influencer') // Example: Replace with real auth check

//   if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
//     next({ name: 'login' })
//   } else {
//     next()
//   }
// })

console.log('router')
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const getUser = await getStorageData(StorageKeys.ADMINUSER)

  // console.log('getUser', getUser)

  if (requiresAuth && !getUser) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
